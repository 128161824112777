import React, { useEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
	Button,
	IdentityDocumentsWrapper,
	NavigationWrapper,
	PickerHOC,
	TargetsWrapper,
	Text,
	UiWrapper,
	UserWrapper,
	View
} from '@coinscrap/webapp-core';
import { CONFIG } from 'config/config';
import { useIsLocalDev } from 'hooks/useIsLocalDev';
import { Wrapper } from 'layout/components/Body/Wrapper';
import { useInitialScrollToTop } from '../../hooks/useInitialScrollToTop';
import Footer from '../../layout/components/Footer/Footer';
import Header from '../../layout/components/Header/Header';

export const IdentityBiometric = PickerHOC({
	dniData: {
		arrayItemMatcher: {
			type: 'value',
			itemPropertyPath: 'main',
			value: true
		},
		sourcePath: 'identityDocuments',
		sourceWrapper: IdentityDocumentsWrapper
	},
	target: {
		sourceWrapper: TargetsWrapper,
		sourcePath: 'targets',
		arrayItemMatcher: {
			type: 'param',
			paramName: 'targetId',
			itemPropertyPath: 'id'
		}
	},
	user: {
		sourcePath: 'user',
		sourceWrapper: UserWrapper
	}
})(() => {
	const { dniData, user, target } = PickerHOC.use();
	const { useHeader, useLoading } = UiWrapper.use();
	const { navigateTo, query } = NavigationWrapper.use();
	const { updateUser } = UserWrapper.use();
	const { loadUserIdentityDocuments } = IdentityDocumentsWrapper.use();
	const isLocalDev = useIsLocalDev();

	// ESTADO
	const appStep = useMemo(() => 9, []);
	let currentLang = 'es';

	// ### DNI PROCESS
	const issuingCountry = user?.metadata?.nationalityIDS?.toUpperCase() || 'ESP';
	const enableSelfie = useMemo(() => (query?.selfie === 'false' ? false : true), [query]);
	const scanManaggerReference = useRef();

	useHeader(<Header mobile={isMobile} buttonEnabled={true} />, []);
	useLoading(false);
	useInitialScrollToTop();

	console.log('👉 dniData', dniData);

	const pendingFlow = useMemo(
		() => dniData.verificationStatus === 'NOT_CREATED' || dniData.verificationStatus === 'PENDING',
		[dniData]
	);
	const successFlow = useMemo(() => dniData.verificationStatus === 'VALID', [dniData]);
	const errorFlow = useMemo(() => dniData.verificationStatus === 'NOT_VALID', [dniData]);

	const [scannedDNI, setscannedDNI] = useState('');
	const [sameBenefDNI, setsameBenefDNI] = useState(false);
	const [diferentToReceived, setdiferentToReceived] = useState(false);

	console.log('🚩 scannedDNI', scannedDNI);
	console.log('🚩 sameBenefDNI', sameBenefDNI);
	console.log('🚩 diferentToReceived', diferentToReceived);

	// IDENTITY MOCK
	const esMock = false;

	const intervalTime = 5000; // Tiempo en milisegundos (5 segundo)
	useEffect(() => {
		if (!pendingFlow) return; // Si ya se alcanzó el valor, no hacer nada

		const interval = setInterval(() => {
			console.log('🎯 loadUserIdentityDocuments');
			loadUserIdentityDocuments(user.id);
		}, intervalTime);

		return () => clearInterval(interval); // Limpia el intervalo cuando cambie la dependencia

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dniData.verificationStatus]);

	console.log('👉 pendingFlow', pendingFlow);
	console.log('👉 successFlow', successFlow);
	console.log('👉 errorFlow', errorFlow);

	useEffect(() => {
		const reviewSuccess = async () => {
			// Respuesta OK, continuamos con lo que venga
			const scannedDNIeffect = dniData?.number;
			setscannedDNI(scannedDNIeffect);

			const sameBenefDNIeffect =
				target?.creationData?.details?.beneficiaryType !== 'generico' &&
				target?.creationData?.details?.beneficiaries?.find(
					(be) => be?.dni.toUpperCase() === scannedDNIeffect.toUpperCase()
				);
			setsameBenefDNI(sameBenefDNIeffect ? true : false);

			const diferentToReceivedeffect = esMock
				? false
				: user?.metadata?.document && scannedDNIeffect?.toUpperCase() !== user?.metadata?.document?.toUpperCase();
			setdiferentToReceived(diferentToReceivedeffect ? true : false);

			if (sameBenefDNIeffect) {
				const userPayload = {
					...user,
					metadata: {
						...user?.metadata,
						identityBiometricError: 'sameBeneficiary'
					}
				};
				await updateUser(userPayload);
			} else if (diferentToReceivedeffect) {
				const userPayload = {
					...user,
					metadata: {
						...user?.metadata,
						identityBiometricError: 'diferentToInitial'
					}
				};
				await updateUser(userPayload);
			} else {
				CONFIG.routing.identityBiometric.activateProduct(navigateTo);
			}
		};
		if (successFlow) {
			reviewSuccess();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [successFlow]);

	useEffect(() => {
		console.log('📌 user', user);
		console.log('📌 dniData', dniData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<Wrapper showProgressBar step={appStep} progressPercent={75} title={''} subtitle={''}>
				<View innerWrapperSt justifyCenterSt alignItemsCenterSt>
					{pendingFlow && (
						<IdentityDocumentsWrapper.SimpleScanManager
							ref={scanManaggerReference}
							identityDocumentId={dniData?.id}
							style={{
								marginTop: 30,
								width: '100%',
								height: isMobile ? '500px' : '500px',
								minHeight: isMobile ? '500px' : '500px',
								overflow: 'hidden'
							}}
							scanConfig={{
								providerIdentifier: esMock ? 'mock' : 'tecalis',
								metadata: {
									kyc: {
										excludedDocTypes: [],
										allowedDocTypes: [`${issuingCountry}_DNI`, `${issuingCountry}_NIE`, `PASSPORT_${issuingCountry}`],
										status_report: ['Verification OK'],
										methods: {
											ReadMrz: true,
											VerifyData: true,
											Images: true,
											Liveness: enableSelfie,
											FaceMatch: enableSelfie,
											FacialRecognition: enableSelfie,
											Selfie: false,
											FraudScoring: true,
											ImagesUrl: false,
											StorageUpload: true,
											Otp: true,
											Location: true,
											CheckFacesNumber: true
										},
										fraudScoring: {
											aeat: true,
											expirationDate: true,
											legalAge: true,
											photocopyCheck: true,
											hologramCheck: false
										}
									},
									front: {
										iframe_mode: true,
										kyc_mode: 'auto',
										liveness_guidance_mode: 'smile',
										skin: 'light',
										borders: true,
										borders_percentage: 50,
										borders_width: 50,
										video_mode: 'native',
										css_url: false,
										wait_after_kyc_success: true,
										'no-spinner': false,
										title_kyc: 'Documento de identidad',
										mirror_mode_pc: true,
										location_config: 'optional',
										default_lang: currentLang,
										force_lang: currentLang,
										accept_manual_text: '',
										new_index: false,
										iccid: false,
										msisdn: false,
										barcode_min: false,
										auditoria: true,
										headless_barcode: false,
										prioridad_interrupcion: 'postergado',
										final_imprimible: false,
										adapt_detector_to_passport: true,
										retries_before_manual_pc: 3,
										retries_before_manual_others: 3,
										logs: false
									}
								}
							}}
						/>
					)}
					{sameBenefDNI && (
						<View
							style={{
								marginTop: !isMobile ? 40 : 20,
								maxWidth: !isMobile ? 600 : undefined
							}}
						>
							<Text headingPrimarySt>Se ha encontrado un error en el listado de beneficiarios.</Text>
							<Text headingSecondarySt>No puedes añadirte tú mismo como beneficiario.</Text>
							<Button
								style={{ marginTop: 30, marginBottom: 0, display: 'inline-block' }}
								onClick={() => {
									CONFIG.routing.identityBiometric.beneficiaries(navigateTo);
								}}
							>
								Modificar
							</Button>
						</View>
					)}
					{diferentToReceived && (
						<View
							style={{
								marginTop: !isMobile ? 40 : 20,
								maxWidth: !isMobile ? 600 : undefined
							}}
						>
							<Text headingPrimarySt errorSt>
								Se ha encontrado un error en el documento añadido.
							</Text>
							<Text headingTertiarySt errorSt>
								El número del documento escaneado no coincide con el que se indicó al inicio del proceso.
							</Text>

							{isLocalDev && (
								<Text headingSecondarySt>
									scannedDNI → {scannedDNI} | initialDNI →{user?.metadata?.document}
								</Text>
							)}
							<Button
								style={{ marginTop: 30, marginBottom: 0 }}
								onClick={() => {
									CONFIG.routing.identityBiometric.identityBiometricSteps(navigateTo);
								}}
							>
								Modificar
							</Button>
						</View>
					)}
					{errorFlow && (
						<>
							<Text headingPrimarySt errorSt>
								❌ Proceso terminado con error
							</Text>
							<Button
								style={{ marginTop: 30, marginBottom: 0 }}
								onClick={() => {
									CONFIG.routing.identityBiometric.identityBiometricUpsert(navigateTo);
								}}
							>
								Reintentar
							</Button>
						</>
					)}
				</View>
			</Wrapper>
			<Footer />
		</>
	);
});
